import React from 'react'
import { Link, graphql } from "gatsby"
import Image from 'gatsby-image'
import { Flex } from 'rebass'

import HighBox from '../components/high-box'
import Layout from '../components/layout'
import HeadingLink from '../components/heading-link'

const getAlbumHeight = (numberOfCoverPhotos) => {
  const coversToHeightsMap = {
    4: ['10rem', '12rem', '15rem', '20rem'],
    3: ['13rem', '17rem', '21rem', '27rem'],
    2: ['16rem', '22rem', '27rem', '34rem'],
  }

  return coversToHeightsMap[numberOfCoverPhotos] || '100%'
}

const AlbumView = ({ title, covers }) =>
  <div className='container'>
    <HighBox height={getAlbumHeight(covers.length)} style={{ display: 'flex'}}>
      {
        covers.map(photo =>
          <div className='photo'>
            <Image style={{ height: '100%' }} fluid={photo.fluid} />
          </div>
        )
      }
    </HighBox>
    <div className='title-container'>
      <HeadingLink>{title}</HeadingLink>
    </div>

    <style jsx>{`
      .container {
        padding-bottom: 5rem;
      }

      .photo {
        width: 100%;
        margin-left: .3rem;
        margin-right: .3rem;
      }

      .title-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: .5rem;
      }
    `}
    </style>
  </div>

const Albums = ({ data }) =>
  <Layout activePage='Albums'>
    <div>
      {
        data.allContentfulAlbum.edges.map(({ node: album }) =>
          <AlbumView {...album} />
        )
      }
    </div>
  </Layout>

export default Albums

export const pageQuery = graphql`
  query {
    allContentfulAlbum(sort: {order: ASC, fields: [createdAt]}) {
      edges {
        node {
          createdAt
          title
          slug
          covers {
            title
            description
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
